import React from 'react';
import { Link } from 'react-router-dom';
import Spacer from '../components/Spacer';

const columnData = [
  (
    <div key="footer--info" className="footer-col">
      <div className="footer--title">
        OUR INFORMATION
      </div>
      <div className="footer__content">
        <div className="footer__content--item">
          <span className="footer__content--header">Location:</span> Galena, MO
        </div>
        <div className="footer__content--item">
          <span className="footer__content--header">Telephone:</span> 417-420-4020  
        </div>
        <div className="footer__content--item">
          <span className="footer__content--header">Email:</span> info@conticorplabs.com
        </div>
      </div>
    </div>
  ),
  (
    <div key="footer-hours" className="footer-col">
      <div className="footer--title">
        BUSINESS HOURS
      </div>
      <div className="footer__content">
        <div className="footer__content--item">
          <span className="footer__content--header">Monday-Friday:</span> 8:30 - 5:00
        </div>
        <div className="footer__content--item">
          <span className="footer__content--header">Saturday-Sunday:</span> Closed
        </div>
      </div>
    </div>
  ),
  (
    <div key="footer-pages" className="footer-col">
      <div className="footer--title">
        PAGES
      </div>
      <div className="footer__content footer__content--links">
        <Link className="footer__content--item" to="/">Conti Corp Labs</Link>
        <Link className="footer__content--item" to="/about">About Us</Link>
        <Link className="footer__content--item" to="/services">Services Offered</Link>
        <Link className="footer__content--item" to="/contact-us">Contact Us</Link>
      </div>
    </div>
  )
]

const Footer = () => (
  <div className="footer" id="footer">
    <div className="footer__wrapper">
      {columnData.map((item, index) => (
        item
      ))}
      <Spacer />
      <footer className="footer--cr">
        © Copyright 2020 Conti Corp Labs | Contact Us or Call for a Quote 417-420-4020
      </footer>
    </div>
  </div>
);

export default Footer;
