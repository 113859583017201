import React from 'react';
import { Container } from 'reactstrap';

const Spacer = () => (
  <Container>
    <div className="spacer" style={{ height: '1px' }} />
  </Container>
);

export default Spacer;
