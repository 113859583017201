import React from 'react';
import Background from '../components/Background';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RightArticle from '../components/RightArticle';
import TopArticle from '../components/TopArticle';
import ContactUs from '../components/Forms/ContactUs';
import Spacer from '../components/Spacer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope  } from '@fortawesome/free-regular-svg-icons'
import { faPhoneSquare } from '@fortawesome/free-solid-svg-icons'

const topItem = {
  type: 'center',
  title: 'Contact Us Today',
  body: (
    <p>
      To request a quote, services, or information feel free to call, email,
      or fill out the below form and we will get in touch with you about your inquiry.
    </p>
  ),
  img: {
  },
  links: [
    {
      title: <FontAwesomeIcon style={{fontSize: "2rem"}} icon={faEnvelope} />,
      url: 'mailto:info@conticorplabs.com',
    },
    {
      title: (<><FontAwesomeIcon style={{fontSize: "2rem"}} icon={faPhoneSquare} /><p className="contact-us__phone">1-417-420-4020</p></>),
      url: 'tel:1-417-420-4020'
    }
  ]
}

const ContactPage = () => (
  <div className="page-container">
    <Header />
    <div className="page contact">
      <TopArticle data={topItem} />
      <Spacer />
      <ContactUs />
    </div>
    <Footer />
    {/* <Background /> */}
  </div>
);

export default ContactPage;
