import React from 'react';
import { Container, Row, Col, Badge } from 'reactstrap';
import { withRouter } from 'react-router';

const Columns = (props) => {
  const { data } = props;
  const defaultJust = 'left';
  let { justification } = data;

  if (justification === undefined) {
    justification = defaultJust;
  }

  let { border } = data;
  if (border === undefined) {
    border = false;
  }

  return (
    <Container className="column__wrapper">
      { data.title &&
        <h1 className="column__title">
          {data.title}
        </h1>
      }
      <Row className="row__wrapper">
        <Col className={`col--${justification} ${border ? 'col-border' : ''}`}>{data.items[0]}</Col>
        { data.items.length >= 2 &&
          <Col className={`col--${justification} ${border ? 'col-border' : ''}`}>{data.items[1]}</Col>
        }
        { data.items.length >= 3 &&
          <Col className={`col--${justification} ${border ? 'col-border' : ''}`}>{data.items[2]}</Col>
        }
        { data.items.length >= 4 &&
          <Col className={`col--${justification} ${border ? 'col-border' : ''}`}>{data.four}</Col>
        }
      </Row>
      { data.links &&
        <div className="links">
          {data.links.map((link, index) => (
            <a key={index} href={link.url}>
              <Badge key={link.title} color="primary" pill>
                {link.title}
              </Badge>
            </a>
          ))}
        </div>
      }
    </Container>
  );
}

export default withRouter(Columns);

// props
// data.title: string of title
// data.links: list of links :: {title: '', url: ''}] 
// data.items: list of items :: React Items :: required*
// data.justification: left, center, right :: default left
