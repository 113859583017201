import React from 'react';
import DesktopNav from "./Navigation/DesktopNav";

const Header = () => (
  <div className="header__wrapper sticky-top">
    <DesktopNav />
  </div>
);

export default Header;
