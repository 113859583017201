import React from 'react';
import { Container, Badge } from 'reactstrap';
import { withRouter } from 'react-router';

const SimpleList = (props) => {
  const { data } = props;
  return (
    <Container className="list__wrapper">
      { data.title &&
        <h4 className="list__title">
          {data.title}
        </h4>
      }
      <ul className="list">
        { data.items.map((item, index) => (
          <li key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)} className="list__item">
            {item}
          </li>
        ))}
        { data.subItems &&
          <ul className="sublist">
            {data.subItems.map((item, index) => (
              <li key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)} className="list__item">
                {item}
              </li>
            ))}
          </ul>
        }
      </ul>
      { data.links &&
        <div className="links">
          {data.links.map((link, index) => (
            <a key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)} href={link.url}>
              <Badge key={link.title} color="primary" pill>
                {link.title}
              </Badge>
            </a>
          ))}
        </div>
      }
    </Container>
  );
}

export default withRouter(SimpleList);

// props
// data.title: string of title
// data.links: list of links :: {title: '', url: ''}] 
// data.items: list of items :: strings :: required*
